<template>
<div>
  <!--<div class="firmware-update">
    <div class="title">Доступна новая<br>прошивка <span class="bold">PLONQ</span></div>
    <div class="content">
        <div class="label">Что нового</div>
        <div class="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
    </div>

    <div class="btn-group">
        <a href="#" class="btn-sync syncing"><span>Начать установку</span></a>
        <a href="#" class="btn-cancel">Отменить</a>
    </div>
  </div>-->
  <div class="firmware-update-info-setup">
    <div class="title">Подготовка к<br>установке...</div>
    <div class="content">
        <div class="label">Пожалуйста, убедитесь, что:</div>
        <div class="setup-group">
            <div class="item-setup">
                <div class="circle" :class="deviceConnect ? 'active' : ''">1</div>
                <div class="description">УСТРОЙСТВО И ТЕЛЕФОН Соединены ПО <span class="bold">BLUETOOTH</span></div>
            </div>

            <div class="item-setup">
                <div class="circle" :class="chargeStatus ? 'active': ''">2</div>
                <div class="description">УСТРОЙСТВО ПОСТАВЛЕНО НА <span class="bold">ЗАРЯДКУ</span> И ЗАРЯЖАЕТСЯ</div>
            </div>
        </div>
    </div>

    <div class="btn-group">
        <a href="#" class="btn-sync" @click="nextStepSetup" :class="(deviceConnect && chargeStatus) ? 'active' : ''"><span>Продолжить</span></a>
        <a href="#" class="btn-cancel" @click="goToMain">Отменить</a>
    </div>
  </div>

    <transition name="slide-fade" mode="out-in">
    <div class="start-firmware-update" v-if="statusProcessUpdate">
        <div class="logo"><img src="~@/assets/img/logo.svg"></div>
        <div class="description flashlight">Устанавливаем обновление</div>
        <div class="progress-bar">
            <div class="progress" :style="`width: ${processUpdatePercent}%;`"></div>
        </div>
        <div class="percent">{{ processUpdatePercent }}%</div>
    </div>
    </transition>

    <transition name="slide-fade" mode="out-in">
    <div class="firmware-done" v-if="statusProcessDone">
        <div class="icon-device"><img src="~@/assets/img/icon-done.svg"></div>
        <div class="description">У вас установленна актуальная версия прошивки</div>
        <a href="#" class="btn-cancel" @click="statusProcessFinish">Закрыть</a>
    </div>
    </transition>

    <transition name="slide-fade" mode="out-in">
    <div class="firmware-error" v-if="statusProcessError">
        <div class="icon-device"><img src="~@/assets/img/icon-error.svg"></div>
        <div class="description">Ошибка при обновлении, повторите попытку позже</div>
        <a href="#" class="btn-cancel" @click="statusProcessErrorClose">Закрыть</a>
    </div>
    </transition>


  <!-- Окно начала прошивки -->
    <div class="slideup" :class="statusSlideupConfirm == true ? 'activate': 'deactivate'">
        <div class="confirm">
            <div class="title">Последние проверки</div>
            <div class="description"><span class="bold">Перед началом установки</span> - убедитесь что индикатор на устройстве загорелся <span class="bold">синим</span> цветом</div>
            <div class="indicator"></div>
            <div href="#" class="btn-confirm" :class="messageTimerToActive ? 'active': ''" @click="clickDFU($event);">Выбрать устройство</div>
        </div>
        <div class="bg-filter"></div>
    </div>
</div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

import SecureDfu from 'web-bluetooth-dfu/lib';
import CRC32 from 'crc-32';

import Package from '../bluetooth/package'

export default defineComponent({
    name: 'FirmwareUpdate',
    setup() {
        const store = useStore();
        const router = useRouter();
        const deviceConnect = computed(() => store.state.deviceConnect);
        const chargeStatus = computed(() => store.state.deviceDetail.chargeStatus);

        const statusProcessUpdate = ref(false);
        const statusProcessDone = ref(false);
        const statusProcessError = ref(false);
        const statusProcessErrorClose = () => { statusProcessError.value = false; }
        const processUpdatePercent = ref(0);
        const statusProcessFinish = () => {
            store.state.notRedirectConnect = true;
            router.push({'name': 'Main'});

            //statusProcessDone.value = false;
            //statusProcessError.value = false;
            //statusProcessUpdate.value = false;
            store.state.deviceConnect = false;
        }

        const goToMain = () => {
            router.push({'name': 'Main'});
        }

        const dfu = new SecureDfu(CRC32.buf);
        // UUID сервиса
        dfu.SERVICE_UUID = 'FE59';
        const plonq = computed(() => store.state.plonq);

        const slideupClose = (element) => {
            element.target.closest('.slideup').style.display = 'block';
            setTimeout(() => {
                element.target.closest('.slideup').style.display = 'none';
            }, 600)
        }

        const statusSlideupConfirm = ref(false);
        const nextStepSetup = async () => {
            if (deviceConnect.value && chargeStatus.value) {
                await plonq.value.startCommand([0x55]);
                store.state.notRedirectConnect = true;
                statusSlideupConfirm.value = true;
            } else {
                alert('Выполните все шаги до начала установки');
            }
        }

        const messageTimerToActive = () => {
            //
        }

        // upload firmware to file[0] object
        async function createFile(url){
            let response = await fetch(url);
            let data = await response.blob();
            let metadata = {
                type: 'application/zip'
            };
            let file = new File([data], "firmware.zip", metadata);
            return file;
        }

        const setTransfer = (state) => {
            if (!state) {
                console.log('setTransfer empty');
                return;
            }
            let transfer = `${state.currentBytes}/${state.totalBytes} ${state.object} bytes written`;
            let progress = parseInt(state.currentBytes / state.totalBytes * 100);
            processUpdatePercent.value = progress;

            return progress;
        }

        // Load a firmware package
        const setPackage = async () => {
            let file = await createFile(store.state.firmwareData.file);
            if (!file) {
                console.log('Нет файла для установки');
                return;
            }

            let pack = new Package(file);
            await pack.load();
            return pack;
        }

        const update = async (dfu, device, pack) => {
            if (!pack) {
                console.log('Pack не существует..')
                return;
            }

            Promise.resolve()
            .then(() => {
                console.log('getBaseImage');
                return pack.getBaseImage()
            })
            .then(image => {
                if (image) {
                    console.log(`Updating ${image.type}: ${image.imageFile}...`);
                    return dfu.update(device, image.initData, image.imageData);
                }
            })
            .then(() => {
                return pack.getAppImage()}
            )
            .then(image => {
                if (image) {
                    console.log(`Updating ${image.type}: ${image.imageFile}...`);
                    return dfu.update(device, image.initData, image.imageData);
                }
            })
            .then(async () => {
                console.log("Update complete!");
                statusProcessDone.value = true;
                // Не находимся в статусе обновления
                store.state.statusUpdateNow = false;
                setTransfer(0);

                setTimeout(async () => {
                    try {
                        let statusConnect = await plonq.value.connect();
                        
                        if (statusConnect['connected']) {
                        // Устройство подключено
                            store.commit('setDeviceConnect', true);
                            // Данные устройства
                            store.commit('setDeviceDetail', await plonq.deviceDetail);
                            await store.dispatch('startGattNotify', plonq);

                            let deviceFullName = `${store.state.deviceDetail.deviceName} ${store.state.deviceDetail.deviceModel}`.toUpperCase();
                            context.dispatch('startNotifyTooltip', {message: `${deviceFullName} подключён`, icon: 'device'});
                            // Останавливаем попытки подключения
                            clearInterval(connectInterval);
                        } else {
                            store.commit('setDeviceConnect', true);
                            store.commit('setSlideupConnectDevice', true);
                        }
                    } catch (e) {
                        console.log('Ошибка подключения к устройству TRY');
                        console.log(e);
                    }
                }, 1500);
            })
            .catch(error => {
                statusProcessUpdate.value = false;
                statusProcessDone.value = false;
                statusProcessError.value = true;
                console.log('Ошибка обновления прошивки');
                console.log(error);
                // Не находимся в статусе обновления
                store.state.statusUpdateNow = false;
            });
        }

        dfu.addEventListener("log", event => {
            //console.log(event.message);
        });
        dfu.addEventListener("progress", event => {
            setTransfer(event);
        });

        const clickDFU = async (e) => {
            let device = await dfu.requestDevice(false, [
                { name: "DfuTarg" },
                { namePrefix: "DfuTarg" }
            ]);

            if (device) {
                slideupClose(e);
                setTimeout(() => {
                    statusProcessUpdate.value = true;
                }, 600)
            } else {
                setTimeout(() => {
                    slideupClose(e);
                }, 600);
            }
            
            console.log('Device..');
            console.log(device);
            console.log('set Dfu Mode...');
            //console.log(await dfu.setDfuMode(device));
            console.log('Set package...');
            let pack = await setPackage();
            console.log('Start update...');
            update(dfu, device, pack);
        }

        return {
            deviceConnect,
            chargeStatus,
            clickDFU,
            statusProcessUpdate,
            processUpdatePercent,
            statusProcessDone,
            statusProcessError,
            statusProcessFinish,
            statusProcessErrorClose,
            messageTimerToActive,
            nextStepSetup,
            statusSlideupConfirm,
            goToMain
        }
    }
});
</script>

<style lang="scss">
    /* SLIDE FADE ANIMATEION */
    .slide-fade-enter-active {
        transition: all .15s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all .15s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter-from, .slide-fade-leave-to {
        //transform: translateY(10px);
        opacity: 0;
    }
    
    .start-firmware-update, .firmware-done, .firmware-error {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: #fff;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        z-index: 9001;

        > .logo {
            margin-bottom: 30px;

            > img {
                width: 100px;
            }
        }

        > .progress-bar {
            width: 80%;
            height: 5px;
            border-radius: 100px;
            background-color: rgba(0, 0, 0, 0.1);
            margin-top: 15px;
            position: relative;
            
            overflow: hidden;

            > .progress {
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;

                width: 0%;

                background-color: #52C306;
                transition: all 0.2s ease;
            }
        }

        > .icon-device {
            width: 60px;
            height: 60px;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-bottom: 50px;

            display: flex;

            > img {
                width: 50px;
                height: 50px;
            }

            &.spin {
                > img {
                    animation-name: spin;
                    animation-duration: 1200ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in-out;
                }
            }
        }

        @keyframes spin { 
            0% { 
                transform: rotate(0deg); 
            } 
            90% {
                transform: rotate(360deg); 
            }
            100% {
                transform: rotate(360deg);    
            }
        }

        > .description {
            font-size: 1.7vh;
            line-height: 2.7vh;
            font-weight: 400;
            font-family: 'Gotham Pro';

            padding: 0px 50px;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 1.8vh;

            &.flashlight {
                animation-name: flashlight;
                animation-duration: 3200ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        }

        > .percent {
            font-size: 3.6vh;
            font-weight: 300;
            font-family: 'Gotham Pro';

            margin-top: 35px;
        }

        @keyframes flashlight {
            0% { 
                opacity: 1;
            } 
            50% { 
                opacity: 0.3;
            }
            100% {
                opacity: 1;
            }
        }

        > .btn-cancel {
            width: 320px;

            position: fixed;
            bottom: 40px;

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 2.2vh;
            font-weight: lighter;
            font-family: 'Gotham Pro';
            font-variant: small-caps;
            text-transform: lowercase;

            letter-spacing: .3px;
            
            text-decoration: none;
            color: #000;
            padding: 0px;
            margin: 0px;
        }
    }

    .firmware-update, .firmware-update-info-setup {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: #fff;

        display: flex;
        flex-direction: column;
        align-items: center;

        z-index: 9001;

        > .title {
            width: calc(100% - 80px);
            margin: 60px 40px 20px;

            font-size: 24px;
            font-weight: lighter;
            line-height: 32px;
            color: #000;
        }

        > .content {
            margin: 0px 40px;

            > .label {
                font-size: 16px;
                font-weight: lighter;
                margin-bottom: 6px;
            }

            > .description {
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
            }

            > .setup-group {
                display: flex;
                flex-direction: column;
                margin-top: 26px;

                > .item-setup {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 20px;

                    > .circle {
                        flex: none;
                        width: 59px;
                        height: 59px;
                        border-radius: 50px;
                        background-color: #FFF;
                        margin: 10px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        margin-right: 25px;

                        font-size: 3.2vh;
                        font-weight: lighter;

                        padding-top: 1px;
                        padding-lefT: 1px;

                        animation-name: flashlight;
                        animation-duration: 2500ms;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;

                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            top: -10px;
                            left: -10px;
                            right: -10px;
                            bottom: -10px;
                            //background-color: #F8F8F8;
                            border: 1px solid #E6E6E6;
                            z-index: -1;

                            border-radius: 50px;
                        }

                        &.active {
                            animation-name: none;
                            border: 1px solid transparent;
                            background-color: #52C306;
                            color: #fff;

                            &:before {
                                border: 1px solid transparent;
                                background-color: #52C30620;
                            }
                        }


                        @keyframes flashlight {
                            0% { 
                                opacity: 1;
                            } 
                            50% { 
                                opacity: 0.2;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                    }

                    > .description {
                        font-size: 2vh;
                        font-weight: 400;
                        font-family: 'Gotham Pro';
                        text-transform: lowercase;
                        font-variant: small-caps;
                    }
                }
            }
        }

        > .btn-group {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            position: fixed;
            bottom: 30px;

            > .btn-sync {
                width: 320px;
                height: 70px;
                border-radius: 50px;
                background-color: #000;
                opacity: 0.8;
                
                display: flex;
                align-items: center;
                justify-content: center;

                font-size: 2.4vh;
                font-weight: 400;
                font-family: 'Gotham Pro';
                font-variant: small-caps;
                text-transform: lowercase;
                color: #fff;

                letter-spacing: .6px;
                opacity: 0.4;
                
                text-decoration: none;
                padding: 0px;
                margin: 35px 0px 25px;

                transition: all 0.2s ease;
                position: relative;

                > span {
                    transition: all 0.2s ease;
                    margin-bottom: 2px;
                }

                &.active {
                    opacity: 1;
                }
            }

            > .btn-cancel {
                width: 320px;

                display: flex;
                align-items: center;
                justify-content: center;

                font-size: 2.2vh;
                font-weight: lighter;
                font-family: 'Gotham Pro';
                font-variant: small-caps;
                text-transform: lowercase;

                letter-spacing: .3px;
                
                text-decoration: none;
                color: #000;
                padding: 0px;
                margin: 0px;
            }
        }
    }
</style>
