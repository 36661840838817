<template>
<div>
    <div class="settings-page">
        <div class="section-title">Раздел тестирования</div>

        <div class="params">
            <div class="item-param">Статус устройства: {{ plonq.deviceConnect ? 'Подключено' : 'Не подключено'}}</div>
            <div class="item-param">Версия прошивки: {{ deviceFirmware }}</div>
            <div class="item-param">MAC адрес: {{ deviceMac }}</div>
            <div class="item-param">Статус зарядки: {{ deviceCharge ? 'Заряжается' : 'Не заряжается' }}</div>
            <div class="item-param">Кол-во затяжек: {{ devicePuff }}</div>
            <div class="item-param">Батарея: {{ deviceBattery }}%</div>
            <div class="item-param">Картридж: {{ deviceCartridge }}</div>
        </div>

        <div class="btn-click" @click="startDeviceConnect">Подключиться к устройству</div>
        <div class="btn-click" @click="setCommand(0x44)">Перезагрузка</div>
        <div class="btn-click" @click="setCommand(0x45)">Перезагрузка (force)</div>
        <div class="btn-click" @click="showUpdateAlert">Обновить прошивку</div>
        <div class="btn-click" @click="setCommand(0x55)">Запуск режима DFU (0x55)</div>
        <div class="btn-click" @click="setCommand(0xD0)">Затяжки каждые 30 сек (0xD0)</div>

        <input type="text" class="input-command" v-model="inputCommand" @keyup.enter="setCommand(inputCommand)">

        <div class="terminal">
            <div class="item" v-for="(item, key) of terminalList" :key="key">{{ item }}</div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { defineComponent, computed, watchEffect, ref } from 'vue';

export default defineComponent({
    name: 'System',
    setup() {
        const store = useStore();
        const deviceConnect = computed(() => store.state.deviceConnect);
        const deviceFirmware = computed(() => store.state.deviceDetail.firmware);
        const deviceMac = computed(() => store.state.deviceDetail.mac);
        const devicePuff = computed(() => store.state.deviceDetail.puffCounter);
        const deviceBattery = computed(() => store.state.deviceDetail.batteryLevel);
        const deviceCartridge = computed(() => store.state.deviceDetail.cartridge);
        const deviceCharge = computed(() => store.state.deviceDetail.chargeStatus);

        const plonq = computed(() => store.state.plonq);
        const terminalList = computed(() => store.state.terminal.reverse());

        const showUpdateAlert = () => {
            store.state.alertUpdateStatus = true;
        }

        const startDeviceConnect = async () => {
            await store.dispatch('connectPlonq');
        }

        const inputCommand = ref(null)
        const setCommand = (hex: any) => {
            switch (hex) {
                case 0x44: hex = [hex]
                    plonq.value.startCommand(hex);
                    store.state.terminal.push('Отправляем команду 0x44');
                    break;
                case 0x45: hex = [hex]
                    plonq.value.startCommand(hex);
                    store.state.terminal.push('Отправляем команду 0x45');
                    break;
                case 0x55: hex = [hex]
                    plonq.value.startCommand(hex);  
                    store.state.terminal.push('Отправляем команду 0x55');
                    break;
                case 0xD0: hex = [hex]
                    plonq.value.startCommand(hex);
                    store.state.terminal.push('Отправляем команду 0xD0');
                    break;
                default:
                    hex = hex.split(' ');
                    plonq.value.startCommand(hex);
                    store.state.terminal.push('Отправляем команду ' + hex);
                    break;
            }
        }

        return {
            plonq,
            terminalList,
            setCommand,
            deviceConnect,
            startDeviceConnect,
            deviceFirmware,
            deviceMac,
            devicePuff,
            deviceBattery,
            deviceCartridge,
            deviceCharge,
            showUpdateAlert,
            inputCommand
        }
    }
});
</script>

<style lang="scss">
    .input-command {
        width: calc(90% - 20px);;
        height: 46px;
        border: 0px;
        background-color: #eee;
        margin: 20px auto;
        margin-left: 5%;
        border-radius: 9px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: 400;

        outline: none;
    }

    .params {
        padding: 10px 40px;

        > .item-param {
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 6px;
        }
    }

    .terminal {
        width: 100%;
        height: 140px;
        background-color: #F5F7FA;

        padding: 20px 40px;

        position: absolute;
        bottom: 80px;

        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        > .item {
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 6px;
        }
    }

    .section-title {
        padding: 30px 40px 10px;
        font-size: 20px;
        font-weight: 400;
    }

    .btn-click {
        width: calc(100% - 80px);
        height: 40px;
        border-radius: 9px;
        background-color: #F5F7FA;
        color: #000;
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        font-size: 1.7vh;
        font-weight: 400;
        text-decoration: none;

        margin: 12px auto 0px;
    }
</style>
