
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'Settings',
    setup() {
        const store = useStore();
        const router = useRouter();
        const deviceDetail = computed(() => store.state.deviceDetail);

        const showUpdateAlert = async () => {
            await store.dispatch('checkFirmwareUpdate')
            //store.state.alertUpdateStatus = true;
        }

        const connectPlonq = async () => {
            try {
                store.state.forceAnyConnect = true
                await store.state.plonq.disconnect();
            } catch (e) {
                console.log('Устройство уже отключено');
            }

            store.state.deviceConnect = false;
            router.push({'name': 'Onboard'});
        }

        return {
            deviceDetail,
            showUpdateAlert,
            connectPlonq
        }
    }
});
