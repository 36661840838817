<template>
    <div class="stats-page">
        <ul class="stats-tabs">
            <li @click="setActiveTab('day')" :class="activeTab == 'day' ? 'active' : ''">Day</li>
            <li @click="setActiveTab('week')" :class="activeTab == 'week' ? 'active' : ''">Week</li>
            <li @click="setActiveTab('month')" :class="activeTab == 'month' ? 'active' : ''">Month</li>
        </ul>

        <div class="tab" v-if="activeTab == 'day' ? 'active' : ''">
            <div class="period-control">
                <div class="prev"><img src="~@/assets/img/arrow-left.svg"></div>
                <div class="title">18 September</div>
                <div class="next"><img src="~@/assets/img/arrow-right.svg"></div>
            </div>

            <div class="chart">
                <canvas id="chart-day" style="height: calc(100% - 150px);"></canvas>
            </div>

            <div class="main-content">
                <div class="counter-group">
                    <div class="item-counter active">
                        <div class="count count-today">123</div>
                        <div class="label">Puffs</div>
                    </div>
                    <div class="item-counter">
                        <div class="count count-week">127</div>
                        <div class="label">Average per day</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab" v-if="activeTab == 'week' ? 'active' : ''">
            <div class="period-control">
                <div class="prev"><img src="~@/assets/img/arrow-left.svg"></div>
                <div class="title">Week</div>
                <div class="next"><img src="~@/assets/img/arrow-right.svg"></div>
            </div>

            <div class="chart">
                <canvas id="chart-week" style="height: calc(100% - 150px);"></canvas>
            </div>

            <div class="main-content">
                <div class="counter-group">
                    <div class="item-counter active">
                        <div class="count count-today">123</div>
                        <div class="label">Puffs</div>
                    </div>
                    <div class="item-counter">
                        <div class="count count-week">127</div>
                        <div class="label">Average per day</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab" v-if="activeTab == 'month' ? 'active' : ''">
            <div class="period-control">
                <div class="prev" @click="setMonthNum((parseInt(setDataMonthNum) - 1))"><img src="~@/assets/img/arrow-left.svg"></div>
                <div class="title">{{ setDataMonth(setDataMonthNum) }}</div>
                <div class="next" @click="setMonthNum((parseInt(setDataMonthNum) + 1))"><img src="~@/assets/img/arrow-right.svg"></div>
            </div>

            <div class="chart">
                <canvas id="chart-month" style="height: calc(100% - 150px);"></canvas>
            </div>

            <div class="main-content">
                <div class="counter-group">
                    <div class="item-counter active">
                        <div class="count count-today">123</div>
                        <div class="label">Puffs</div>
                    </div>
                    <div class="item-counter">
                        <div class="count count-week">127</div>
                        <div class="label">Average per day</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { defineComponent, ref } from 'vue';
import Chart from 'chart.js/auto';

export default defineComponent({
  name: 'Stats',
  setup () {
	const store = useStore();
    
    const activeTab = ref('day');
    const setActiveTab = (tab: any) => {

        switch (tab) {
            case 'day': 
                activeTab.value = 'day'; 
                //setStats('chart-day');
            break;
            case 'week': 
                activeTab.value = 'week'; 
                //setStats('chart-week');
                break;
            case 'month': 
                activeTab.value = 'month'; 
                setStats('chart-month', dataPack['month']['2021'][setDataMonthNum.value]['labels'], dataPack['month']['2021'][setDataMonthNum.value]['data']);
            break;
        }
    }

    const dataPack: any = {
        'day': {
            2021: {
                1: {},
                2: {},
                3: {},
                4: {},
                5: {},
                6: {},
                7: {},
                8: {},
                9: {},
                10: {},
                11: {},
                12: {}
            }
        },
        'week': {
            2021: {
                1: {},
                2: {},
                3: {},
                4: {},
                5: {},
                6: {},
                7: {},
                8: {},
                9: {

                },
                10: {},
                11: {},
                12: {}
            }
        },
        'month': {
            '2021': {
                'text': ["January","February","March","April","May","June","July","August","September","October","November","December"],
                '0': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                '1': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                '2': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                '3': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                '4': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [233,184,117,182,153,148,172,179,198,204,141,222,191,230,151,156,178,180,205,143,249,129,219,171,139,248,154,246,131,174]
                },
                '5': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [126,121,225,205,222,145,250,202,128,182,178,196,140,181,204,212,201,129,167,207,231,221,163,161,197,157,124,199,224,158]
                },
                '6': {
                    "puffs": 12900,
                    "avarage": 13414,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [159,179,169,95,106,134,83,157,158,140,204,145,150,195,88,171,202,99,107,82,81,205,129,138,163,190,186,166,143,101],
                },
                '7': {
                    "puffs": 12948,
                    "avarage": 13339,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [125,130,83,147,90,167,183,133,170,146,92,188,165,190,187,162,204,91,151,136,80,186,128,142,97,107,189,145,113,198],
                },
                '8': {
                    "puffs": 12471,
                    "avarage": 1310,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [234, 324, 197, 188, 211, 215, 200, 290, 243, 223, 190, 181, 234, 324, 197, 188, 211, 215, 200, 290, 243, 223, 190, 181, 141, 58, 180, 212, 190, 188],
                },
                '9': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                '10': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                '11': {
                    "puffs": 0,
                    "avarage": 0,
                    "labels": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                    "data": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            }
        }
    }

    const setDataMonth = (num: string) => {
        setDataMonthNum.value = num;
        return dataPack['month']['2021']['text'][parseInt(setDataMonthNum.value)]
    }

    var myChart: any = {}
    const setStats = (chartId: any, labels: any, data: any) => {
        console.log(data);
        setTimeout(() => {
            var ctx: any = document.querySelector('#' + chartId);
            myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
                        barPercentage: 0.3,
                        barThickness: 4,
                        maxBarThickness: 8,
                        minBarLength: 2,
                        data: data,
                        backgroundColor: [
                            '#EFE4C5'
                        ],
                        borderRadius: 3,
                    }]
                },
                options: {
                    responsive: true,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    locale: 'ru-RU',
                    scales: {
                        y: {
                            min: 0,
                            //max: 300,
                            type: 'linear',
                            display: true,
                            position: 'right',
                            grid: {
                                drawBorder: false,
                                color: '#E6E6E6'
                            },
                            ticks: {
                                color: '#a8a8a8',
                                font: {
                                    family: 'GothamPro', // Your font family
                                    size: 11,
                                },
                            },
                        },
                        x: {
                            grid: {
                                display: false,
                                offset: true,
                                drawBorder: false
                            },
                            ticks: {
                                font: {
                                    family: 'GothamPro', // Your font family
                                    size: 11,
                                },
                            },
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        }
                    }

                }
            });
        }, 250);
    }

    const setDataMonthNum = ref('8');
    const setMonthNum = (num: number) => {
        if (num < 1) {
            setDataMonthNum.value = '0';
        }
        
        if (num > 11) {
            setDataMonthNum.value = '11';
        }

        if (num >= 0 && num <= 11) {
            setDataMonthNum.value = (num).toString()
            myChart.data.datasets[0].labels = dataPack['month']['2021'][setDataMonthNum.value]['labels']
            myChart.data.datasets[0].data = dataPack['month']['2021'][setDataMonthNum.value]['data']
            myChart.update();
        }
    }

    return {
        activeTab,
        setActiveTab,

        setMonthNum,
        setDataMonth,
        setDataMonthNum
    }
  }
});
</script>