
import { useStore } from 'vuex';
import { defineComponent, computed, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'sync',
    setup() {
        const store = useStore();
        const router = useRouter();
        
        const activeStep = ref(1);
        const connectDeviceInProcess = computed(() => store.state.connectDeviceInProcess);
        const deviceConnect = computed(() => store.state.deviceConnect);
        const startFindDevice = async () => {
            await store.dispatch('connectPlonq');
            if (deviceConnect.value) {
                router.push({'name': 'Main'});
            } else {
                console.log('Отмена подключения устройста')
            }
        }

        watchEffect(() => {
            if (store.state.deviceConnect == true) {
                router.push({'name': 'Main'});
            }
        })

        return {
            activeStep,
            startFindDevice,
            connectDeviceInProcess,
            deviceConnect
        }
  }
});
