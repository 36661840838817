import { createStore } from 'vuex'
import PlonqBLE from '../bluetooth/connect'
import router from '../router/index'
import axios from 'axios';
import Cookies from 'js-cookie'

function getDateTime() {
  var now: any     = new Date(); 
  var year: any    = now.getFullYear();
  var month: any    = now.getMonth()+1; 
  var day: any      = now.getDate();
  var hour: any     = now.getHours();
  var minute: any   = now.getMinutes();
  var second: any   = now.getSeconds(); 
  if(month.toString().length == 1) {
       month = '0'+month;
  }
  if(day.toString().length == 1) {
       day = '0'+day;
  }   
  if(hour.toString().length == 1) {
       hour = '0'+hour;
  }
  if(minute.toString().length == 1) {
       minute = '0'+minute;
  }
  if(second.toString().length == 1) {
       second = '0'+second;
  }   
  var dateTime = year+'/'+month+'/'+day+' '+hour+':'+minute+':'+second;   
   return dateTime;
}

function eventPuff (event: any)  {
  let puffCount = event.target.value.getInt16();
  console.log(event.target.value.getUint16().toString(16));
  let context = event.currentTarget.context
  //context.commit('setPuffCount', puffCount);
  console.log(getDateTime(), '> Event puff count is ' + puffCount);
  context.state.terminal.push(`Событие затяжки: ${puffCount} (кол-во)`);
}

function eventCharge (event: any) {
  let chargeStatus = event.target.value.getUint8();
  let context = event.currentTarget.context
  context.commit('setChargeStatus', chargeStatus);
  console.log(getDateTime(), '> Charge status ' + chargeStatus);

  context.state.terminal.push(`Событие зарядки: ${chargeStatus}`);

  if (chargeStatus) 
    context.dispatch('startNotifyTooltip', {message: `Charge connected`, icon: 'charge'});
  else 
    context.dispatch('startNotifyTooltip', {message: `Charge disconnected`, icon: 'charge'});
}

function eventBattery (event: any) {
  let battery = event.target.value.getUint8();
  let context = event.currentTarget.context

  context.commit('setBatteryLevel', battery);
  console.log(getDateTime(), '> Заряд батареи ' + battery);
  context.state.terminal.push(`Событие батареи: ${battery}%`);
  if (battery < 10) {
    context.dispatch('startNotifyTooltip', {message: `Charging required`, icon: 'charge'});
  }
}

function eventCartridge (event: any) {
  let cartridge = event.target.value.getUint8();
  let context = event.currentTarget.context

  context.commit('setCartridge', cartridge);
  console.log(getDateTime(), '> Картридж ' + cartridge);
  context.state.terminal.push(`Событие картриджа: ${cartridge}`);

  if (cartridge > 0) 
    context.dispatch('startNotifyTooltip', {message: `Cartridge on`, icon: 'tasty'});
  else 
    context.dispatch('startNotifyTooltip', {message: `Cartridge off`, icon: 'tasty'});
}

function eventRSSI (event: any) {
  let rssi = event.target.value.getUint8();

  let context = event.currentTarget.context
  context.commit('setRSSI', rssi);
  context.commit('setRSSITime', Date.now());
}

function eventResistance (event: any) {
  let res = event.target.value.getUint32().toString('16').split(""); //.reverse().join("");
  let newString = []
  for (let i = 0; i < res.length; i+= 2) {
    newString.push(res[i] + res[i + 1])
  }

  let reversed = parseInt(newString.reverse().join(""), 16);
  //console.log(res.join(""));
  //console.log(newString.join(""));
  //console.log("-----");

  //console.log(res.join(""));
  //console.log('New string', reversed);

  //console.log('Dec', parseInt(res, 16))
  //console.log('Convert: ', event.target.value.getUint32().toString('16'))
  //console.log('Hex: ', event.target.value.getUint32());
  //res = parseInt(res, 16)

  let context = event.currentTarget.context
  context.commit('setResistance', reversed);
}

function eventNordic (event: any) {
  let tx = event.target.value;
  console.log('NORDIC EVENT NORDIC');
  console.log(tx);
  let context = event.currentTarget.context

  console.log(getDateTime(), '> TX: ' + tx);
}

function eventNordicTx (event: any) {
  let tx = event.target.value;
  console.log('NORDIC EVENT NORDIC EVENT NORDIC EVENT');
  console.log(tx);
  let context = event.currentTarget.context

  console.log(getDateTime(), '> TX: ' + tx);
}

export default createStore({
  state: {
    terminal: new Array(),
    onboard: false,
    slideupConfirmAge: false,
    slideupSelectDevice: false,
    slideupConnectDevice: false,
    statusUpdateNow: false,
    // Если сами выбираем подключение другого устройства
    forceAnyConnect: false,
    // Если прошиваем
    notRedirectConnect: false,

    deviceConnect: false,
    deviceDetail: {
      firmware: '',
      firmwareVersion: '',
      hardware: '',
      deviceName: '',
      deviceModel: '',
      batteryLevel: 0,
      chargeStatus: false,
      puffCounter: 0,
      unixtime: 0,
      mac: '',
      cartridge: 0,
      tx: null,
      rssi: 0,
      rssiTime: 0,
      resistance: 0
    },

    notifyTooltip: {
      status: false,
      message: null,
      icon: null
    },

    // BLE Object
    plonq: null,
    firmwareToken: null,
    firmwareData: {
      version: '',
      file: '',
      charges: ''
    },
    connectDeviceInProcess: false,
    // Есть ли обновление - окно
    alertUpdateStatus: false,
    // Если нет обновления
    alertNotAllowedUpdate: false,

    valuesChart: [],
  },
  mutations: {
    setSlideupConnectDevice(state, payload) {
      state.slideupConnectDevice = payload;
    },

    setAlertUpdateStatus(state, payload) {
      state.alertUpdateStatus = payload;
    },

    setAlertNotAllowedUpdate(state, payload) {
      console.log('alertNotAllowedUpdate')
      state.alertNotAllowedUpdate = payload;
    },

    setFirmwareToken(state, payload) {
      state.firmwareToken = payload;
    },

    setFirmwareData(state, payload) {
      state.firmwareData = payload;
    },
    
    setPlonqHandle(state, payload) {
      state.plonq = payload;
    }, 

    setStartNotifyTooltip(state, payload) {
      state.notifyTooltip.status = true;
      state.notifyTooltip.message = payload.message;
      state.notifyTooltip.icon = payload.icon;
    },

    setStopNotifyTooltip(state) {
      state.notifyTooltip.status = false;
    },

    setConnectDeviceInProcess(state, payload) {
      state.connectDeviceInProcess = payload;
    },

    setDeviceConnect(state, payload) {
      state.deviceConnect = payload;
    },

    setDeviceDetail(state, payload) {
      state.deviceDetail = payload;
    },

    setPuffCount(state, payload) {
      //setPuffCount
      let mac = window.btoa(state.deviceDetail.mac.replaceAll(':', '-'));
      if (!Cookies.get(mac)) {
        Cookies.set(mac, payload)
        state.deviceDetail.puffCounter = payload;
      } else {
        let countCookie: any = Cookies.get(mac)
        let count: any = 0
        if (payload == 0)
          count = (parseInt(countCookie)).toString()
        else
          count = (parseInt(countCookie) + 1).toString()

        Cookies.set(mac, count)
        state.deviceDetail.puffCounter = count;
      }
      //state.deviceDetail.puffCounter = payload;
    },

    setChargeStatus(state, payload) {
      state.deviceDetail.chargeStatus = payload;
    },

    setBatteryLevel(state, payload) {
      state.deviceDetail.batteryLevel = payload;
    },

    setCartridge(state, payload) {
      state.deviceDetail.cartridge = payload;
    },

    setRSSI(state, payload) {
      state.deviceDetail.rssi = payload;
    },

    setResistance(state, payload) {
      state.deviceDetail.resistance = payload;
    },

    setRSSITime(state, payload) {
      state.deviceDetail.rssiTime = payload;
    }

  },
  actions: {
    async firmwareServerRegistration(context, mac) {
      if (mac) {
        let formData = new FormData();
        formData.append('username', mac);
        formData.append('el_cigarette_id', '1');

        try {
          let { data } = await axios({
            url: 'https://ponq.ru:8000/rest-auth/registration/',
            method: 'post', 
            data: formData,
            headers: {"Content-Type": "multipart/form-data"}
          });
          
          if (Object.keys(data).includes('key'))
            return data;
        } catch (e) {
          console.log(`${getDateTime()} Ошибка регистрации`);
          return e;
        }
      }
    },

    async firmwareServerLogin(context, mac) {
      if (mac) {
        let formData = new FormData();
        formData.append('username', mac);
        formData.append('el_cigarette_id', '1');

        try {
          let { data } = await axios({
            url: 'https://ponq.ru:8000/rest-auth/login/',
            method: 'post', 
            data: formData,
            headers: {"Content-Type": "multipart/form-data"}
          });
          
          if (Object.keys(data).includes('key'))
            return data;
        } catch (e) {
          console.log(`${getDateTime()} Ошибка авторизации`);
          return e;
        }
      }
    },

    async firmwareServerAutoLogin(context, mac) {
      // Регистрация
      let reg = await context.dispatch('firmwareServerRegistration', mac);
      if (reg != null) {
        if (Object.keys(reg).includes('key')) {
          // Авторизаци
          context.commit('setFirmwareToken', reg.key);
          console.log(`Регистрация: ` + reg.key);
          return reg.key;
        } else {
          let login = await context.dispatch('firmwareServerLogin', mac);

          if (Object.keys(login).includes('key')) {
            context.commit('setFirmwareToken', login.key);
            console.log(`Авторизация: ` + login.key);
            return login.key;
          }

          return false;
        }
      }
    },

    async getLastFirmwareVersion(context, key) {
      let { data } = await axios({
        url: 'https://ponq.ru:8000/user/device/firmware/',
        method: 'get', 
        headers: {"Authorization": "Token " + key}
      });

      if (Object.keys(data).includes('version')) {
        context.commit('setFirmwareData', data);
        console.log(getDateTime(), data);
        return data;
      } else {
        console.log('Не удалось получить версию прошивки');
        return data;
      }
    },

    async initDataFirmware(context, mac) {
      let autoLogin = await context.dispatch('firmwareServerAutoLogin', mac);
      if (autoLogin) {
        await context.dispatch('getLastFirmwareVersion', autoLogin);
      }

      return true;
    },

    async startNotifyTooltip (context, {message, icon}) {
      context.commit('setStartNotifyTooltip', {
        message: message,
        icon: icon
      })

      setTimeout(() => context.commit('setStopNotifyTooltip'), 2700);
    },

    async checkFirmwareUpdate (context) {
      let macLine = context.state.deviceDetail.mac.replaceAll(':', '-');
      let token = await context.dispatch('initDataFirmware', macLine);

 
      let fwDeviceVersion = context.state.deviceDetail.firmware;
      let fwSLastVersion = context.state.firmwareData.version;

      console.log(fwDeviceVersion + ' ' + fwSLastVersion)
      if (fwDeviceVersion !== fwSLastVersion) {
        context.commit('setAlertUpdateStatus', true);
      } else {
        context.commit('setAlertNotAllowedUpdate', true)
      }
 
    },

    async connectPlonq (context) {
      console.log(`${getDateTime()} Запускаем подключение..`);
      const plonq = new PlonqBLE();
      context.commit('setPlonqHandle', plonq);
      try {
        context.commit('setConnectDeviceInProcess', true);
        try {
        await plonq.request();
        } catch (e) {
          console.log(e);
        }

        let connectStatus = await plonq.connect();
        // Убираем ручной коннект другого устройства
        context.state.forceAnyConnect = false;
        console.log(getDateTime(), plonq.device);

        if (connectStatus.connected) {
          // Устройство подключено
          context.commit('setDeviceConnect', true);
          // Данные устройства
          context.commit('setDeviceDetail', await plonq.deviceDetail);
          // Статус процесса подключения - подключились
          context.commit('setConnectDeviceInProcess', false);
          
          console.log(getDateTime(), context.state.deviceDetail);
          console.log(getDateTime(), context.state.deviceDetail.deviceName);

          let macLine = context.state.deviceDetail.mac.replaceAll(':', '-');
          let token = await context.dispatch('initDataFirmware', macLine);

          setTimeout(() => {
              let deviceFullName = `${context.state.deviceDetail.deviceName} ${context.state.deviceDetail.deviceModel}`.toUpperCase();
              setTimeout(() => {
                context.dispatch('startNotifyTooltip', {message: `${deviceFullName} connected`, icon: 'device'});
              }, 600);
          }, 600);

          setTimeout(() => {
            let fwDeviceVersion = context.state.deviceDetail.firmware;
            let fwSLastVersion = context.state.firmwareData.version;

            if (fwDeviceVersion !== fwSLastVersion) {
              context.commit('setAlertUpdateStatus', true);
            }
          }, 3200);
        }

        console.log('-------------------------------');

        plonq.device.addEventListener("gattserverdisconnected", async function (event: any) {
            console.log(getDateTime(), 'Дисконнент')

            // Устройство отключено
            context.commit('setDeviceConnect', false);
            let deviceFullName = `${context.state.deviceDetail.deviceName} ${context.state.deviceDetail.deviceModel}`.toUpperCase();
            context.dispatch('startNotifyTooltip', {message: `${deviceFullName} disconnected`, icon: 'device'});

            let statusConnect = {'connected': false};
            if (context.state.forceAnyConnect == false) {
              let connectInterval = setInterval(async () => {
                  statusConnect = await plonq.connect();
                  
                  if (statusConnect['connected']) {
                    // Устройство подключено
                      context.commit('setDeviceConnect', true);
                      // Данные устройства
                      context.commit('setDeviceDetail', await plonq.deviceDetail);
                      // Останавливаем и перезапускаем
                      await context.dispatch('stopGattNotify', plonq);
                      await context.dispatch('startGattNotify', plonq);

                      //context.commit('setPuffCount', 0);

                      context.dispatch('startNotifyTooltip', {message: `Connection restored`, icon: 'device'});
                      // Останавливаем попытки подключения
                      clearInterval(connectInterval);
                  } else {
                    context.commit('setDeviceConnect', true);
                    context.commit('setSlideupConnectDevice', true);
                  }
              }, 3000);
            }
        });
        
        //context.commit('setPuffCount', 0);
        await context.dispatch('startGattNotify', plonq);
        
      } catch (e) {
        context.commit('setConnectDeviceInProcess', false);
        console.log(getDateTime(), e);
        console.log(getDateTime(), 'Ошибка подключения к устройству');
      }
    },

    async stopGattNotify (context, plonq) {
      try {
        plonq.charsHandle.service.puffCounter.removeEventListener('characteristicvaluechanged', eventPuff);
        await plonq.charsHandle.service.puffCounter.stopNotifications();
      } catch (e) {
        console.log('error puff event');
        console.log(e);
      }

      // Статус зарядки
      try {
        plonq.charsHandle.service.charge.removeEventListener('characteristicvaluechanged', eventCharge);
        plonq.charsHandle.service.charge.stopNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // Процент заряда
        plonq.charsHandle.battery.batteryLevel.removeEventListener('characteristicvaluechanged', eventBattery);
        await plonq.charsHandle.battery.batteryLevel.stopNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // Картридж
        plonq.charsHandle.service.cartridge.removeEventListener('characteristicvaluechanged', eventCartridge);
        await plonq.charsHandle.service.cartridge.stopNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // RSSI
        plonq.charsHandle.service.rssi.removeEventListener('characteristicvaluechanged', eventRSSI);
        
        await plonq.charsHandle.service.rssi.stopNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // RSSI
        plonq.charsHandle.service.resistance.removeEventListener('characteristicvaluechanged', eventResistance);
        
        await plonq.charsHandle.service.resistance.stopNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }
    },

    async startGattNotify (context, plonq) {
      console.log(getDateTime(), 'Start dispatch events..');
      // Подписываемся на события затяжек
      console.log(getDateTime(), 'PUFF EVENT');
      console.log(getDateTime(), plonq.charsHandle.service.puffCounter);
      try {
        plonq.charsHandle.service.puffCounter.addEventListener('characteristicvaluechanged', eventPuff);
        plonq.charsHandle.service.puffCounter.context = context

        await plonq.charsHandle.service.puffCounter.startNotifications();
      } catch (e) {
        console.log('error puff event');
        console.log(e);
      }

      // Статус зарядки
      try {
        plonq.charsHandle.service.charge.addEventListener('characteristicvaluechanged', eventCharge);
        plonq.charsHandle.service.charge.context = context

        await plonq.charsHandle.service.charge.startNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // Процент заряда
        plonq.charsHandle.battery.batteryLevel.addEventListener('characteristicvaluechanged', eventBattery);
        plonq.charsHandle.battery.batteryLevel.context = context

        await plonq.charsHandle.battery.batteryLevel.startNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // Картридж
        plonq.charsHandle.service.cartridge.addEventListener('characteristicvaluechanged', eventCartridge);
        plonq.charsHandle.service.cartridge.context = context

        await plonq.charsHandle.service.cartridge.startNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // Nordic
        plonq.charsHandle.nordic.nordicRead.addEventListener('characteristicvaluechanged', eventNordic);
        plonq.charsHandle.nordic.nordicRead.context = context
        
        await plonq.charsHandle.nordic.nordicRead.startNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // Nordic
        plonq.charsHandle.nordic.tx.addEventListener('characteristicvaluechanged', eventNordicTx);
        plonq.charsHandle.nordic.tx.context = context
        
        await plonq.charsHandle.nordic.tx.startNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // RSSI
        plonq.charsHandle.service.rssi.addEventListener('characteristicvaluechanged', eventRSSI);
        plonq.charsHandle.service.rssi.context = context
        
        await plonq.charsHandle.service.rssi.startNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }

      try {
        // RSSI
        plonq.charsHandle.service.resistance.addEventListener('characteristicvaluechanged', eventResistance);
        plonq.charsHandle.service.resistance.context = context
        
        await plonq.charsHandle.service.resistance.startNotifications();
      } catch (e) {
        console.log(getDateTime(), e);
      }
      // Актиируем подписки
    }
  },
  modules: {
  }
})
