<template>
<div>
  <div class="settings-page">
      <div class="logo"><img src="~@/assets/img/logo.svg"></div>
      <div class="device-detail">
        <div class="item-detail">Device {{ (deviceDetail.deviceName + ' ' + deviceDetail.deviceModel).toUpperCase() }}</div>
        <div class="item-detail">Firmware version {{ deviceDetail.firmware }}</div>
        <div class="item-detail">MAC адрес {{ deviceDetail.mac }}</div>
      </div>
      <div class="device-control">
        <div class="item-control" @click="showUpdateAlert">
            <div class="circle">
                <img src="~@/assets/img/sync-black.svg">
            </div>
            <div class="description">check firmware<br>update <span class="bold">plonq</span></div>
        </div>
        <div class="item-control" @click="connectPlonq">
            <div class="circle">
                <img src="~@/assets/img/icon-add-device.svg">
            </div>
            <div class="description">connect another<br><span class="bold">plonq</span></div>
        </div>
      </div>

      <!--<div class="btn-click" @click="startBootloader">Запуск режима DFU</div>-->
  </div>
  <!--<div class="get-firmware-update">
    <div class="icon-device"><img src="~@/assets/img/sync-black.svg"></div>
    <div class="description">Проверяем обновления прошивки...</div>
  </div>-->
</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'Settings',
    setup() {
        const store = useStore();
        const router = useRouter();
        const deviceDetail = computed(() => store.state.deviceDetail);

        const showUpdateAlert = async () => {
            await store.dispatch('checkFirmwareUpdate')
            //store.state.alertUpdateStatus = true;
        }

        const connectPlonq = async () => {
            try {
                store.state.forceAnyConnect = true
                await store.state.plonq.disconnect();
            } catch (e) {
                console.log('Устройство уже отключено');
            }

            store.state.deviceConnect = false;
            router.push({'name': 'Onboard'});
        }

        return {
            deviceDetail,
            showUpdateAlert,
            connectPlonq
        }
    }
});
</script>

<style lang="scss">
    .btn-click {
        width: calc(100% - 80px);
        height: 52px;
        border-radius: 9px;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        font-size: 1.7vh;
        font-weight: 400;
        text-decoration: none;

        margin: auto;
    }

    .get-firmware-update {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: #fff;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        z-index: 9001;

        > .icon-device {
            width: 60px;
            height: 60px;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-bottom: 50px;

            > img {
                width: 50px;
                height: 50px;
                animation-name: spin;
                animation-duration: 1200ms;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
            }
        }

        @keyframes spin { 
            0% { 
                transform: rotate(0deg); 
            } 
            90% {
                transform: rotate(360deg); 
            }
            100% {
                transform: rotate(360deg);    
            }
        }

        > .description {
            font-size: 2vh;
            font-weight: 400;
            font-family: 'Gotham Pro';

            animation-name: flashlight;
            animation-duration: 4000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        @keyframes flashlight {
            0% { 
                opacity: 1;
            } 
            50% { 
                opacity: 0.3;
            }
            100% {
                opacity: 1;
            }
        }
    }

    .settings-page {
		width: 100%;
		height: 100vh;

        > .logo {
            padding-top: 40px;
            margin-left: 40px;
            margin-bottom: 50px;

            > img {
                height: 20px;
            }
		}

        > .device-detail {
            width: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            padding-left: 40px;

            > .item-detail {
                width: auto;
                margin-bottom: 1vh;

                font-size: 1.8vh;
                font-weight: 300;
                color: #000;
                
                opacity: .5;
            }
        }

        > .device-control {
            width: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            padding-left: 40px;
            margin-top: 25px;

            > .item-control {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 22px;

                > .circle {
                    flex: none;
                    width: 76px;
                    height: 76px;
                    border-radius: 50px;
                    background-color: #F8F8F8;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    margin-right: 20px;

                    > img {
                        width: 20px;
                        height: 20px;
                    }
                }

                > .description {
                    font-size: 2.2vh;
                    font-weight: 400;
                    font-family: 'Gotham Pro';
                    text-transform: lowercase;
                    font-variant: small-caps;
                }
            }
        }
    }
</style>
