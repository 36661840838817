<template>
<div>
<!--<div class="first-page">
          <img src="assets/img/logo.svg">
      </div>-->

    <div class="onboarding-slide">
        <div class="logo"><img src="~@/assets/img/logo.svg"></div>

        <div class="onboarding-page" :style="'left: ' + slideLeftNumber[0] + 'px'">
            <div class="title">Manage smoking<br>with <span class="bold">PLONQ</span></div>
            <div class="app-image"><img src="~@/assets/img/app_general.png"></div>
            <a href="javascript://" class="next-slide" @click="nextSlide(2)">Next</a>
        </div>
        <div class="onboarding-page" :style="'left: ' + slideLeftNumber[1] + 'px'">
            <div class="title">Follow the<br>smoking statistics</div>
            <div class="app-image"><img src="~@/assets/img/app_slide_stats.png"></div>
            <a hef="javascript://" class="next-slide" @click="nextSlide(3)">Next</a>
        </div>
        <div class="onboarding-page" :style="'left: ' + slideLeftNumber[2] + 'px'">
            <div class="title">Gradually reduce<br>the intensity</div>
            <div class="app-image"><img src="~@/assets/img/app_slide_slow.png"></div>
            <a hef="javascript://" class="next-slide" @click="confirmAge">Ok, next</a>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { defineComponent, computed, ref } from 'vue';
import Cookies from 'js-cookie'

export default defineComponent({
    name: 'onboard',
    setup() {
        const store = useStore();
        const router = useRouter();
        const slideLeftNumber = ref([0, window.innerWidth, (window.innerWidth * 2)])

        const confirmAge = () => {
            store.state.slideupConfirmAge = true;
            store.state.onboard = true;
            Cookies.set('onboard', 'true')
        }

        if (Cookies.get('onboard') == 'true') {
            if (store.state.deviceConnect == false) {
                router.push({'name': 'Sync'});
            } else {
                router.push({'name': 'Main'});
            }
        }

        const nextSlide = async (number: number) => {
            switch (number) {
                case 1:
                    slideLeftNumber.value = [0, window.innerWidth, window.innerWidth * 2]
                    break;
                case 2:
                    slideLeftNumber.value = [window.innerWidth - (window.innerWidth * 2), 0, window.innerWidth]
                    break;
                case 3:
                    slideLeftNumber.value = [window.innerWidth - (window.innerWidth * 2) * 2, window.innerWidth - (window.innerWidth * 2), 0]
                    break;
                default:;
            }
        }

        return {
            slideLeftNumber,
            nextSlide,
            confirmAge
        }
  }
});
</script>
