
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { defineComponent, computed, ref, watchEffect, onMounted } from 'vue';
import Cookies from 'js-cookie'

export default defineComponent({
    name: 'App',
    setup () {
        const store = useStore();
        const router = useRouter();
        //console.log(PlonqBLE);
        const alertUpdateStatus = computed(() => store.state.alertUpdateStatus);
        // Окно - если актуальная прошивка
        const alertNotAllowedUpdate = computed(() => store.state.alertNotAllowedUpdate);
        const slideupConfirmAge = computed(() => store.state.slideupConfirmAge);

        const connectDeviceInProcess = computed(() => store.state.connectDeviceInProcess);
        const deviceConnect = computed(() => store.state.deviceConnect);

        const connectPlonq = async (e: any) => {
            await store.dispatch('connectPlonq');

            if (store.state.deviceConnect) {
                store.state.slideupConnectDevice = false;
                slideupClose(e);
            } else {
                store.dispatch('startNotifyTooltip', {message: `Failed connected`, icon: 'device'});
            }
        };

        function slideupClose (element: any) {
            element.target.closest('.slideup').style.display = 'block';
            setTimeout(() => {
                element.target.closest('.slideup').style.display = 'none';
            }, 600)
        }

        const slideupConfirmAgeStatus = (status: boolean, e: any) => {
            if (status == true) {
                store.state.slideupConfirmAge = false;
                slideupClose(e);

                setTimeout(() => {
                    router.push({'name': 'Sync'});
                    //store.state.slideupSelectDevice = true;
                }, 600);
            } else {
                window.location.href = 'http://localhost:8080';
            }
        }
        const notifyTooltip = computed(() => store.state.notifyTooltip);

        const alertUpdateCancel = () => {
            store.commit('setAlertUpdateStatus', false);
        }

        const alertUpdateGo = () => {
            router.push({'name': 'FirmwareUpdate'});
            store.commit('setAlertUpdateStatus', false);
        }


        const alertNotAllowedUpdateClose = () => {
            store.commit('setAlertNotAllowedUpdate', false);
        }

        const fwCurrentVersion = computed(() => store.state.deviceDetail.firmware);
        const fwServerVersion = computed(() => store.state.firmwareData.version);
        const slideupConnectDevice = computed(() => store.state.slideupConnectDevice);

        const statusUpdateNow = computed(() => store.state.statusUpdateNow);

        onMounted(() => {
            if (store.state.onboard == false) {
                if (Cookies.get('onboard') != 'true') {
                    router.push({'name': 'Onboard'});
                } else {
                    store.state.onboard = true   
                }
            }

            if (store.state.deviceDetail.firmware.length < 1 && store.state.onboard == true) {
                store.state.slideupConnectDevice = true;
            }
        })

        watchEffect(() => {
            if (store.state.deviceConnect == false && store.state.notRedirectConnect == false) {
                router.push({'name': 'Sync'});
            }
        })

        return {
            notifyTooltip,
            slideupConfirmAge,
            slideupConfirmAgeStatus,
            slideupClose,
            alertUpdateStatus,
            alertUpdateGo,
            alertUpdateCancel,
            fwCurrentVersion,
            fwServerVersion,
            slideupConnectDevice,
            connectPlonq,
            connectDeviceInProcess,
            deviceConnect,
            statusUpdateNow,
            alertNotAllowedUpdate,
            alertNotAllowedUpdateClose
        }
    }
});
