<template>
<div>
    <div class="sync-page">
        <div class="sync-preview">
            <div class="preview-bg"></div>
        </div>
        <div class="content">
            <div class="sync-description" v-if="activeStep == 1">
                Bring the <span class="bold">PLONQ</span> device to your phone and click <span class="bold">Connect</span>
            </div>
            <a href="#" class="btn-sync" :class="connectDeviceInProcess ? 'syncing' : ''" @click="startFindDevice()">
                <div class="icon-sync">
                    <div class="sync-bg"></div>
                </div>
                <span>{{ deviceConnect == true ? 'Connected' : 'Connect' }}</span>
            </a>
            <!--<a href="#" class="btn-cancel">Отменить</a>-->
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { defineComponent, computed, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'sync',
    setup() {
        const store = useStore();
        const router = useRouter();
        
        const activeStep = ref(1);
        const connectDeviceInProcess = computed(() => store.state.connectDeviceInProcess);
        const deviceConnect = computed(() => store.state.deviceConnect);
        const startFindDevice = async () => {
            await store.dispatch('connectPlonq');
            if (deviceConnect.value) {
                router.push({'name': 'Main'});
            } else {
                console.log('Отмена подключения устройста')
            }
        }

        watchEffect(() => {
            if (store.state.deviceConnect == true) {
                router.push({'name': 'Main'});
            }
        })

        return {
            activeStep,
            startFindDevice,
            connectDeviceInProcess,
            deviceConnect
        }
  }
});
</script>
