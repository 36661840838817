<template>
<div>
    <div class="wrapper">
    <router-view v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in">
            <component :is="Component" :key="$router.path"></component>
        </transition>   
    </router-view>
    </div>

    <!-- Окно 18+ -->
    <div class="slideup" :class="slideupConfirmAge == true ? 'activate': 'deactivate'">
        <div class="over-18">
            <div class="title">Are you over<br>18 years old?</div>
            <div class="description">I certify that I am at<br>least 18 years old</div>
            <div class="btn-group">
                <a href="#" class="btn active" @click="slideupConfirmAgeStatus(true, $event)">Yes</a>
                <a href="#" class="btn" @click="slideupConfirmAgeStatus(false, $event)">No</a>
            </div>
        </div>
        <div class="bg-filter"></div>
    </div>
    
    <!--<div class="slideup" :class="(slideupConnectDevice == true && statusUpdateNow != true) ? 'activate': 'deactivate'">
        <div class="confirm">
            <div class="title">Подключение</div>
            <div class="description" style="line-height: 2.8vh;">Нажмите кнопку подключения и выберите из списка устройство <span class="bold">PLONQ X</span></div>
            <a href="#" class="btn-sync" :class="connectDeviceInProcess ? 'syncing' : ''" @click="connectPlonq($event);">
                <div class="icon-sync">
                    <div class="sync-bg"></div>
                </div>
                <span>{{ deviceConnect == true ? 'Подключено' : 'Подключить' }}</span>
            </a>
        </div>
        <div class="bg-filter"></div>
    </div>-->

    <transition name="slide-fadeup" mode="out-in">
        <div class="alert-update" v-if="alertUpdateStatus">
            <div class="content">
                <div class="title">Update<br>available</div>
                <div class="total-version">
                    <span>Current version</span>
                    <div class="version">{{ fwCurrentVersion }}</div>
                </div>
                <div class="icon-arrow"><img src="~@/assets/img/icon-arrow-down.svg"></div>
                <div class="new-version">
                    <div class="version">{{ fwServerVersion }}</div>
                    <span>New version</span>
                </div>

                <div class="btn-update" @click="alertUpdateGo">Update</div>
                <div class="btn-cancel" @click="alertUpdateCancel">Update later</div>
            </div>
            <div class="bg-filter"></div>
        </div>
    </transition>

    <transition name="slide-fadeup" mode="out-in">
        <div class="alert-update" v-if="alertNotAllowedUpdate">
            <div class="content">
                <div class="title-small">The latest version of the<br>update is installed</div>
            
                <div class="btn-update" @click="alertNotAllowedUpdateClose">Close</div>
            </div>
            <div class="bg-filter"></div>
        </div>
    </transition>

    <div class="tooltip-notify" :class="notifyTooltip.status ? 'activate' : ''">
        <div class="icon">
            <img v-if="notifyTooltip.icon == 'device'" src="~@/assets/img/bluetooth.svg">
            <img v-if="notifyTooltip.icon == 'charge'" src="~@/assets/img/battery.svg">
            <img v-if="notifyTooltip.icon == 'tasty'" src="~@/assets/img/tasty.svg">
        </div>
        <div class="text">{{ notifyTooltip.message }}</div>
    </div>

    <transition name="slide-fade" mode="out-in">
        <div class="main-menu" v-if="$router.currentRoute.value.name == 'Main' 
                                    //|| $router.currentRoute.value.name == 'Stats' 
                                    || $router.currentRoute.value.name == 'Settings'
                                    || $router.currentRoute.value.name == 'System'"
        >
            <router-link to="/"><img src="~@/assets/img/icon-main.svg"></router-link>
            <!--<router-link to="/stats"><img src="~@/assets/img/icon-stats.svg"></router-link>-->
            <router-link to="/settings"><img src="~@/assets/img/icon-settings.svg"></router-link>
            <router-link to="/system"><img src="~@/assets/img/icon-flash.svg"></router-link>
        </div>
    </transition>
</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { defineComponent, computed, ref, watchEffect, onMounted } from 'vue';
import Cookies from 'js-cookie'

export default defineComponent({
    name: 'App',
    setup () {
        const store = useStore();
        const router = useRouter();
        //console.log(PlonqBLE);
        const alertUpdateStatus = computed(() => store.state.alertUpdateStatus);
        // Окно - если актуальная прошивка
        const alertNotAllowedUpdate = computed(() => store.state.alertNotAllowedUpdate);
        const slideupConfirmAge = computed(() => store.state.slideupConfirmAge);

        const connectDeviceInProcess = computed(() => store.state.connectDeviceInProcess);
        const deviceConnect = computed(() => store.state.deviceConnect);

        const connectPlonq = async (e: any) => {
            await store.dispatch('connectPlonq');

            if (store.state.deviceConnect) {
                store.state.slideupConnectDevice = false;
                slideupClose(e);
            } else {
                store.dispatch('startNotifyTooltip', {message: `Failed connected`, icon: 'device'});
            }
        };

        function slideupClose (element: any) {
            element.target.closest('.slideup').style.display = 'block';
            setTimeout(() => {
                element.target.closest('.slideup').style.display = 'none';
            }, 600)
        }

        const slideupConfirmAgeStatus = (status: boolean, e: any) => {
            if (status == true) {
                store.state.slideupConfirmAge = false;
                slideupClose(e);

                setTimeout(() => {
                    router.push({'name': 'Sync'});
                    //store.state.slideupSelectDevice = true;
                }, 600);
            } else {
                window.location.href = 'http://localhost:8080';
            }
        }
        const notifyTooltip = computed(() => store.state.notifyTooltip);

        const alertUpdateCancel = () => {
            store.commit('setAlertUpdateStatus', false);
        }

        const alertUpdateGo = () => {
            router.push({'name': 'FirmwareUpdate'});
            store.commit('setAlertUpdateStatus', false);
        }


        const alertNotAllowedUpdateClose = () => {
            store.commit('setAlertNotAllowedUpdate', false);
        }

        const fwCurrentVersion = computed(() => store.state.deviceDetail.firmware);
        const fwServerVersion = computed(() => store.state.firmwareData.version);
        const slideupConnectDevice = computed(() => store.state.slideupConnectDevice);

        const statusUpdateNow = computed(() => store.state.statusUpdateNow);

        onMounted(() => {
            if (store.state.onboard == false) {
                if (Cookies.get('onboard') != 'true') {
                    router.push({'name': 'Onboard'});
                } else {
                    store.state.onboard = true   
                }
            }

            if (store.state.deviceDetail.firmware.length < 1 && store.state.onboard == true) {
                store.state.slideupConnectDevice = true;
            }
        })

        watchEffect(() => {
            if (store.state.deviceConnect == false && store.state.notRedirectConnect == false) {
                router.push({'name': 'Sync'});
            }
        })

        return {
            notifyTooltip,
            slideupConfirmAge,
            slideupConfirmAgeStatus,
            slideupClose,
            alertUpdateStatus,
            alertUpdateGo,
            alertUpdateCancel,
            fwCurrentVersion,
            fwServerVersion,
            slideupConnectDevice,
            connectPlonq,
            connectDeviceInProcess,
            deviceConnect,
            statusUpdateNow,
            alertNotAllowedUpdate,
            alertNotAllowedUpdateClose
        }
    }
});
</script>

<style lang="scss">
@font-face {
	font-family: 'Gotham Pro';
	src: url('~@/assets/fonts/GothamPro-Light.eot');
	src: local('Gotham Pro Light'), local('~@/assets/fonts/GothamPro-Light'),
		url('~@/assets/fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
		url('~@/assets/fonts/GothamPro-Light.woff') format('woff'),
		url('~@/assets/fonts/GothamPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@/assets/fonts/GothamPro.eot');
	src: local('Gotham Pro'), local('GothamPro'),
		url('~@/assets/fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
		url('~@/assets/fonts/GothamPro.woff') format('woff'),
		url('~@/assets/fonts/GothamPro.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@/assets/fonts/GothamPro-Medium.eot');
	src: local('Gotham Pro Medium'), local('~@/assets/fonts/GothamPro-Medium'),
		url('~@/assets/fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('~@/assets/fonts/GothamPro-Medium.woff') format('woff'),
		url('~@/assets/fonts/GothamPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@/assets/fonts/GothamPro-Bold.eot');
	src: local('Gotham Pro Bold'), local('~@/assets/fonts/GothamPro-Bold'),
		url('~@/assets/fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('~@/assets/fonts/GothamPro-Bold.woff') format('woff'),
		url('~@/assets/fonts/GothamPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@/assets/fonts/GothamPro-Black.eot');
	src: local('Gotham Pro Black'), local('~@/assets/fonts/GothamPro-Black'),
		url('~@/assets/fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
		url('~@/assets/fonts/GothamPro-Black.woff') format('woff'),
		url('~@/assets/fonts/GothamPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@import '~@/assets/css/main.scss';

/* SLIDE FADE ANIMATEION */
.slide-fade-enter-active {
    transition: all .15s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-leave-active {
    transition: all .15s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter-from, .slide-fade-leave-to {
    //transform: translateY(10px);
    opacity: 0;
}

/* SLIDE FADE ANIMATEION */
.slide-fadeup-enter-active {
    transition: all .25s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fadeup-leave-active {
    transition: all .25s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fadeup-enter-from, .slide-fadeup-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
</style>
