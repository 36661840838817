import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Main from '../views/Main.vue'
import Settings from '../views/Settings.vue'
import System from '../views/System.vue'
import FirmwareUpdate from '../views/FirmwareUpdate.vue'
import Stats from '../views/Stats.vue'
import Onboard from '../views/Onboard.vue'
import Sync from '../views/Sync.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/system',
    name: 'System',
    component: System
  },
  {
    path: '/firmware-update',
    name: 'FirmwareUpdate',
    component: FirmwareUpdate
  },
  {
    path: '/onboard',
    name: 'Onboard',
    component: Onboard
  },
  {
    path: '/sync',
    name: 'Sync',
    component: Sync
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router