
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { defineComponent, computed, ref } from 'vue';
import Cookies from 'js-cookie'

export default defineComponent({
    name: 'onboard',
    setup() {
        const store = useStore();
        const router = useRouter();
        const slideLeftNumber = ref([0, window.innerWidth, (window.innerWidth * 2)])

        const confirmAge = () => {
            store.state.slideupConfirmAge = true;
            store.state.onboard = true;
            Cookies.set('onboard', 'true')
        }

        if (Cookies.get('onboard') == 'true') {
            if (store.state.deviceConnect == false) {
                router.push({'name': 'Sync'});
            } else {
                router.push({'name': 'Main'});
            }
        }

        const nextSlide = async (number: number) => {
            switch (number) {
                case 1:
                    slideLeftNumber.value = [0, window.innerWidth, window.innerWidth * 2]
                    break;
                case 2:
                    slideLeftNumber.value = [window.innerWidth - (window.innerWidth * 2), 0, window.innerWidth]
                    break;
                case 3:
                    slideLeftNumber.value = [window.innerWidth - (window.innerWidth * 2) * 2, window.innerWidth - (window.innerWidth * 2), 0]
                    break;
                default:;
            }
        }

        return {
            slideLeftNumber,
            nextSlide,
            confirmAge
        }
  }
});
