
import { useStore } from 'vuex';
import { defineComponent, computed, watchEffect, ref } from 'vue';

export default defineComponent({
    name: 'System',
    setup() {
        const store = useStore();
        const deviceConnect = computed(() => store.state.deviceConnect);
        const deviceFirmware = computed(() => store.state.deviceDetail.firmware);
        const deviceMac = computed(() => store.state.deviceDetail.mac);
        const devicePuff = computed(() => store.state.deviceDetail.puffCounter);
        const deviceBattery = computed(() => store.state.deviceDetail.batteryLevel);
        const deviceCartridge = computed(() => store.state.deviceDetail.cartridge);
        const deviceCharge = computed(() => store.state.deviceDetail.chargeStatus);

        const plonq = computed(() => store.state.plonq);
        const terminalList = computed(() => store.state.terminal.reverse());

        const showUpdateAlert = () => {
            store.state.alertUpdateStatus = true;
        }

        const startDeviceConnect = async () => {
            await store.dispatch('connectPlonq');
        }

        const inputCommand = ref(null)
        const setCommand = (hex: any) => {
            switch (hex) {
                case 0x44: hex = [hex]
                    plonq.value.startCommand(hex);
                    store.state.terminal.push('Отправляем команду 0x44');
                    break;
                case 0x45: hex = [hex]
                    plonq.value.startCommand(hex);
                    store.state.terminal.push('Отправляем команду 0x45');
                    break;
                case 0x55: hex = [hex]
                    plonq.value.startCommand(hex);  
                    store.state.terminal.push('Отправляем команду 0x55');
                    break;
                case 0xD0: hex = [hex]
                    plonq.value.startCommand(hex);
                    store.state.terminal.push('Отправляем команду 0xD0');
                    break;
                default:
                    hex = hex.split(' ');
                    plonq.value.startCommand(hex);
                    store.state.terminal.push('Отправляем команду ' + hex);
                    break;
            }
        }

        return {
            plonq,
            terminalList,
            setCommand,
            deviceConnect,
            startDeviceConnect,
            deviceFirmware,
            deviceMac,
            devicePuff,
            deviceBattery,
            deviceCartridge,
            deviceCharge,
            showUpdateAlert,
            inputCommand
        }
    }
});
