<template>
<div class="main-page">

	<div class="info">
		<div class="toggle">
			<div class="btn" @click="setTab(0);" :class="tab == 0 ? 'active' : ''">RSSI</div>
			<div class="btn" @click="setTab(1);" :class="tab == 1 ? 'active' : ''">Resistance</div>	
		</div>
		<span>{{ deviceDetail.deviceName }} {{ deviceDetail.deviceModel }}</span>
		<span>Hardware: {{ deviceDetail.hardware }}</span>
		<span>Firmware: {{ deviceDetail.firmware }}</span>
		<span>MAC адрес: {{ deviceDetail.mac }}</span>
	</div>

	<div class="panel">
		<div class="metrics">
			<div class="item">
				<div class="icon"><img src="~@/assets/img/bluetooth.svg"></div>
				<div class="text">{{ deviceConnect ? 'Connected' : 'Disabled' }}</div>
			</div>
			<div class="item">
				<div class="icon"><img src="~@/assets/img/plug.png"></div>
				<div class="text">{{ deviceCharge ? 'Charge' : 'No charge' }}</div>
			</div>
			<div class="item">
				<div class="icon"><img src="~@/assets/img/battery.svg"></div>
				<div class="text">{{ deviceConnect ? deviceDetail.batteryLevel : '0' }}%</div>
			</div>
			<div class="item">
				<div class="icon"><img src="~@/assets/img/tasty.svg"></div>
				<div class="text">{{ getCartridge }}</div>
			</div>
			<div class="item">
				<div class="icon"><img src="~@/assets/img/smoke.png"></div>
				<div class="text">{{ puffCounter }}</div>
			</div>
			<div class="item">
				<div class="icon"><img src="~@/assets/img/signal.svg"></div>
				<div class="text">{{ getRSSI }}</div>
			</div>
			<div class="item">
				<div class="icon"><img src="~@/assets/img/signal.svg"></div>
				<div class="text">{{ getResistance }}</div>
			</div>
		</div>
		<div class="chart" v-if="tab == 0" style="height: 220px; overlow: hidden; margin: 20px;">
			<canvas id="chart-id" style="height: 220px"></canvas>
		</div>
		<div class="chart" v-if="tab > 0" style="height: 220px; overlow: hidden; margin: 20px;">
			<canvas id="chart-id-res" style="height: 220px"></canvas>
		</div>
	</div>
</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { defineComponent, computed, ref, watchEffect, onBeforeMount } from 'vue';
import Chart from 'chart.js/auto';

export default defineComponent({
name: 'Main',
setup () {
	const store = useStore();
	let dataChart: any = [];

	var samples = 20;
	var speed = 50;
	let timeout = samples * speed;
	var values: any = [];
	var labels: any = [];
	var charts: any = null;
	var value = 0;
	var scale = 1;

	var tab = ref(0)
	const setTab = (id: number) => {
		tab.value = id
		initCharts()
	}

	const skipped = (ctx: any, value: any) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
	const down = (ctx: any, value: any) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;
	var chartResistance: any = null
	const valuesResistance: any = ref([])
	const labelsResistance: any = ref([])

	const initCharts = () => {
		setTimeout(() => {
			if (tab.value == 0) {

				const initialize = () => {
					var ctx: any = document.querySelector('#chart-id');
					charts = new Chart(ctx, {
						type: 'line',
						data: {
							labels: labels,
							datasets: [
								{
									borderWidth: 1,
									label: '',
									data: values,
									borderColor: "#FA4B4B",
									fill: false,
									tension: 0.1,
									segment: {
										borderColor: ctx => skipped(ctx, '#000') || down(ctx, '#FA4B4B'),
										borderDash: ctx => skipped(ctx, [6, 6]),
									}
								}
							]
						},
						options: {
							maintainAspectRatio: false,
							elements: {
								point:{
									radius: 1
								}
							},
							responsive: true,
							plugins: {
								title: {
									display: false,
									text: 'Chart.js Line Chart - Cubic interpolation mode'
								},
								legend: {
									display: false
								},
							},
							interaction: {
							intersect: false,
							},
							scales: {
								x: {
									display: false,
									title: {
										display: false
									},
									grid: {
										drawBorder: false,
										color: '#eee'
									},
								},
								y: {
									display: true,
									title: {
										display: false,
									text: 'Value'
									},
									grid: {
										drawBorder: false,
										color: '#eee'
									},
									//suggestedMin: 60,
									//suggestedMax: 270
								}
							}
						}
					})
				}

				const updateCharts = () => {
					charts.update();
				}

				const advance = () => {
					if (values[0] !== null && scale < 4) {
						updateCharts();
					}

					//progress();
					updateCharts();

					setTimeout(function () {
						requestAnimationFrame(advance);
					}, speed);
				}

				initialize();
				advance();

				let prevTick = ref(0)
				let rssi = ref(0)
				setInterval(() => {
					//values.push({x: new Date, y: store.state.deviceDetail.rssi})
					//labels.push(values.length);
					if (prevTick.value == store.state.deviceDetail.rssiTime) {
						rssi.value = NaN
					} else {
						rssi.value = store.state.deviceDetail.rssi
					}

					if (values.length > 50) {
						values.splice(values.length - 50, 1)
						labels.splice(labels.length - 50, 1);

						values.push({x: new Date, y: rssi.value})
						labels.push(values.length);
					} else {
						values.push({x: new Date, y: rssi.value})
						labels.push(values.length);
					}

					prevTick.value = store.state.deviceDetail.rssiTime 
				}, 1000)


			} else {

				// RESISTANCE ==================
				const initialize = () => {
					var ctx: any = document.querySelector('#chart-id-res');
					chartResistance = new Chart(ctx, {
						type: 'line',
						data: {
							labels: labelsResistance.value,
							datasets: [
								{
									borderWidth: 1,
									label: '',
									data: valuesResistance.value,
									borderColor: "#FA4B4B",
									fill: false,
									tension: 0.1,
									segment: {
										borderColor: ctx => skipped(ctx, '#000') || down(ctx, '#FA4B4B'),
										borderDash: ctx => skipped(ctx, [6, 6]),
									}
								}
							]
						},
						options: {
							maintainAspectRatio: false,
							elements: {
								point:{
									radius: 1
								}
							},
							responsive: true,
							plugins: {
								title: {
									display: false,
									text: 'Chart.js Line Chart - Cubic interpolation mode'
								},
								legend: {
									display: false
								},
							},
							interaction: {
							intersect: false,
							},
							scales: {
								x: {
									display: false,
									title: {
										display: false
									},
									grid: {
										drawBorder: false,
										color: '#eee'
									},
								},
								y: {
									display: true,
									title: {
										display: false,
									text: 'Value'
									},
									grid: {
										drawBorder: false,
										color: '#eee'
									},
									//suggestedMin: 60,
									//suggestedMax: 270
								}
							}
						}
					})
				}

				const updateCharts = () =>{
					chartResistance.update();
				}

				const advance = () => {
					if (values[0] !== null && scale < 4) {
						updateCharts();
					}

					//progress();
					updateCharts();

					setTimeout(function () {
						requestAnimationFrame(advance);
					}, speed);
				}

				initialize();
				advance();
			}
			
		}, 500)
	}


	const today = () => {
		
		var now: any       = new Date(); 
		var month: any     = now.getMonth()+1;
		var monthName: any = null
		var day: any       = now.getDate();

		switch (month) {
			case 1: monthName = 'January'; break;
			case 2: monthName = 'February'; break;
			case 3: monthName = 'March'; break;
			case 4: monthName = 'April'; break;
			case 5: monthName = 'May'; break;
			case 6: monthName = 'June'; break;
			case 7: monthName = 'July'; break;
			case 8: monthName = 'August'; break;
			case 9: monthName = 'September'; break;
			case 10: monthName = 'October'; break;
			case 11: monthName = 'November'; break;
			case 12: monthName = 'December'; break;
		}

		if(day.toString().length == 1) {
			day = '0'+day;
		}   

		var dateTime = `${day} ${monthName}`
		return dateTime.toLowerCase();
	}
	 
	const deviceConnect = computed(() => store.state.deviceConnect);
	const deviceDetail = computed(() => store.state.deviceDetail);
	const getRSSI = computed(() => store.state.deviceDetail.rssi);
	const getResistance = computed(() => store.state.deviceDetail.resistance);
	const getCartridge = computed(() => {
		let cartridge = deviceDetail.value.cartridge;
		let description = null;
		switch (cartridge) {
			case 0: description = 'No cartridge'; break;
			case 1: description = 'Pineapple'; break;
			case 2: description = 'Mango'; break;
			case 3: description = 'Cherry'; break;
			case 4: description = 'Tabacco'; break;
			case 5: description = 'Mint'; break;
			case 6: description = 'Green tea'; break;
			case 7: description = 'Tabacco'; break;
			//case 7: description = 'Резерв 1'; break;
			case 8: description = 'Reserve'; break;
			//case 255: description = 'Технический'; break;
			case 255: description = 'Mint'; break;
			default: description = 'New taste'; break;
		}

		return description;
	});

	const deviceCharge = computed(() => {
		return store.state.deviceDetail.chargeStatus;
	})

	watchEffect(() => {
		let res = store.state.deviceDetail.resistance
		if (chartResistance != null) {
			/*if (valuesResistance.value.length > 5) {
				valuesResistance.value.splice(valuesResistance.value.length - 5, 1)
				labelsResistance.value.splice(labelsResistance.value.length - 5, 1);

				valuesResistance.value.push({x: new Date, y: res})
				labelsResistance.value.push(valuesResistance.value.length);
			} else {*/
				valuesResistance.value.push({x: new Date, y: res})
				labelsResistance.value.push(valuesResistance.value.length);
			//}
		}
	})

	onBeforeMount(() => {
		initCharts();
	})

	const puffCounter = computed(() => store.state.deviceDetail.puffCounter);

	return {
		deviceConnect,
		deviceDetail,
		getCartridge,
		puffCounter,
		getRSSI,
		getResistance,
		deviceCharge,
		today,
		tab,
		setTab
	}
}
});
</script>

<style lang="scss">
.main-page {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;

	> .info {
		width: 100%;
		height: 40px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		> span {
			font-size: 14px;
			font-weight: bold;
			margin-right: 32px;
		}

		> .toggle {
			display: flex;
			flex-direction: row;
			margin-right: 60px;

			> .btn {
				padding: 6px 12px;
				background-color: #e7e7e7;
				border-radius: 4px;
				font-size: 13px;
				font-weight: bold;

				&:first-child {
					margin-right: 10px;
				}

				&.active {
					color: red;
				}
			}
		}
	}

	> .panel {
		width: 100%;
		display: flex;
		flex-direction: column;

		> .metrics {
			width: 100%;
			display: flex;
			flex-direction: row;

			padding-top: 30px;

			> .item {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				> .icon {
					width: 44px;
					height: 44px;
					margin-bottom: 20px;
					display: flex;
					align-items: center;
					justify-content: center;

					> img {
						width: 18px;
					}
				}

				> .text {
					font-size: 15px;
					font-weight: 500;
					text-align: center;
				}
			}
		}
	}
}
</style>
